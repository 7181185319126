// MARK : event handlers

function clickHandler( e: MouseEvent | TouchEvent ) {
	// Check if target is available
	if ( !e.target || !( e.target instanceof HTMLElement ) ) {
		return;
	}

	// make sure target is an explainer
	if ( !e.target.hasAttribute( 'explainer' ) ) {
		return;
	}

	// Don't let the event go any further, we want to handle this.
	// This will prevent links from being clicked if an explainer is added in there.
	// It's a bad practice, we know, but we should be prepared for anything
	e.preventDefault();
	e.stopPropagation();

	if ( e.target.classList.contains( 'is-animating' ) ) {
		return;
	}

	toggleText( e.target );
}

// MARK : methods

function toggleText( el:HTMLElement ) {
	// no data, no animation
	if ( !( el.hasAttribute( 'foreign' ) && el.hasAttribute( 'native' ) ) ) {
		return;
	}

	// Check if user wants to have animation at all
	if ( !window.matchMedia( '(prefers-reduced-motion: no-preference)' ).matches ) {
		// if user prefers no animation (or they don't have the option to choose), just toggle the text
		el.innerHTML = invisibleText( el ) ?? '';
		el.classList.toggle( 'has-native-visible' );

		return;
	}

	// user may see an animation
	el.classList.toggle( 'is-animating' );
	cycleDelete( el );

	return;
}

function invisibleText( el: HTMLElement ): string {
	if ( el.classList.contains( 'has-native-visible' ) ) {
		return el.getAttribute( 'foreign' ) ?? '';
	}

	return el.getAttribute( 'native' ) ?? '';
}

function cycleDelete( el:HTMLElement ) {
	// Slice 1 characters per cycle
	el.innerHTML = el.innerHTML.slice( 0, Math.max( 0, el.innerHTML.length - 1 ) );

	if ( 1 > el.innerHTML.length ) {
		// start the typing cycle
		setTimeout( () => {
			cycleTyping( el );
		}, 320 );
	} else {
		// continue the delete cycle
		setTimeout( () => {
			cycleDelete( el );
		}, 32 );
	}
}

function cycleTyping( el:HTMLElement ) {
	// Add 1 charater per cycle
	el.innerHTML = invisibleText( el ).slice( 0, el.innerHTML.length + 1 );

	if ( el.innerHTML.length < invisibleText( el ).length ) {
		// repeat the typing cycle
		setTimeout( () => {
			cycleTyping( el );
		}, 32 );
	} else {
		el.classList.toggle( 'is-animating' );
		el.classList.toggle( 'has-native-visible' );
	}
}

// MARK : init

window.addEventListener( 'touchstart', clickHandler );
window.addEventListener( 'click', clickHandler );
