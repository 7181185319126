class MrCarousel extends HTMLElement {
	// MARK : properties

	#currentIndex = 0;

	#delay = 5000;

	#animateDelay = 128; // The transition delay from carousel.css

	#ticker: number | null = null;

	#animating: boolean | null = false;

	#clickHandler = ( e: MouseEvent ): void => {
		// ignore clicks with modifier keys : shift, ctrl, alt,...
		if ( e.metaKey ) {
			return;
		}

		// Check if target exist and is instance of htmlelement
		if ( !e.target || !( e.target instanceof HTMLElement ) ) {
			return;
		}

		// Unknown trigger, not handling this event.
		if (
			!e.target.hasAttribute( 'data-carousel-next' ) &&
			!e.target.hasAttribute( 'data-carousel-previous' ) &&
			!e.target.hasAttribute( 'data-carousel-play-pause' )
		) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		if ( e.target.hasAttribute( 'data-carousel-next' ) ) {
			this.goToNextItem();

			return;
		}

		if ( e.target.hasAttribute( 'data-carousel-previous' ) ) {
			this.goToPreviousItem();

			return;
		}

		if ( e.target.hasAttribute( 'data-carousel-play-pause' ) ) {
			if ( !this.paused ) {
				this.pause();
				e.target.setAttribute( 'aria-label', e.target.dataset.play ?? 'Play' );

				return;
			}

			this.play();
			e.target.setAttribute( 'aria-label', e.target.dataset.stop ?? 'Stop' );

			// Immediately show next item if user clicks play
			this.goToNextItem();
		}
	};

	#tick = (): void => {
		if ( this.paused ) {
			return;
		}

		this.goToNextItem();
	};

	// MARK : lifecycle

	connectedCallback() {
		this.addEventListener( 'click', this.#clickHandler );

		if ( this.paused ) {
			this.play();
		}
	}

	disconnectedCallback() {
		this.removeEventListener( 'click', this.#clickHandler );

		if ( !this.paused ) {
			this.pause();
		}

		// Reset States
		this.#currentIndex = 0;
		this.#delay = 5000;
		this.#ticker = null;
		this.#animating = false;
	}

	// MARK : methods

	private goToPreviousItem() {
		if ( this.#animating ) {
			return;
		}

		const items = this.querySelectorAll( '[data-carousel-item]' );
		const newIndex = indexMinusOne( this.#currentIndex, items.length, true );
		if ( newIndex === this.#currentIndex ) {
			return;
		}


		this.#currentIndex = newIndex;
		this.transition( newIndex );
	}

	private goToNextItem() {
		if ( this.#animating ) {
			return;
		}

		const items = this.querySelectorAll( '[data-carousel-item]' );
		const newIndex = indexPlusOne( this.#currentIndex, items.length, true );
		if ( newIndex === this.#currentIndex ) {
			return;
		}

		this.#currentIndex = newIndex;
		this.transition( newIndex );
	}

	private transition( index: number ) {
		if ( !this.paused ) {
			// Clear and restart the Ticker if the carousel is playing.
			if ( null !== this.#ticker ) {
				window.clearTimeout( this.#ticker );
			}

			this.#ticker = window.setTimeout( this.#tick, this.#delay );
		}

		this.#animating = true;

		// Fallback in case the transition events don't work
		const delayPromise = new Promise( ( resolve ) => {
			setTimeout(
				resolve,
				this.#animateDelay + 50 // pad with 50ms, this should leave enough time for the animations to run
			);
		} );

		// Wait for transition to finish
		const transitionDonePromise = new Promise<void>( ( resolve ) => {
			const carouselTransitionEnd = ( e: TransitionEvent ) => {
				// Check if target exist and is instance of htmlelement
				if ( !e.target || !( e.target instanceof HTMLElement ) ) {
					return;
				}

				// check if target has attribute data-carousel-item
				if ( !e.target.hasAttribute( 'data-carousel-item' ) ) {
					return;
				}

				this.removeEventListener( 'transitionend', carouselTransitionEnd );

				resolve();
			};

			this.addEventListener( 'transitionend', carouselTransitionEnd );
		} );

		// Start the race
		Promise.race( [
			delayPromise,
			transitionDonePromise,
		] ).then( () => {
			// Render to current index
			this.updateStateAfterTransition( index );
			// Re-enable transitions.
			this.#animating = false;
		} );
	}

	private updateStateAfterTransition( index: number ) {
		const items = this.querySelectorAll( '[data-carousel-item]' );
		const length = items.length;

		// check if items not available
		if ( 2 > length ) {
			return;
		}

		// get current, previous and next items
		const previous = items[indexMinusOne( index, length, true )];
		const current = items[index];
		const next = items[indexPlusOne( index, length, true )];

		// set attributes
		// first reset all items
		items.forEach( ( item ) => {
			item.removeAttribute( 'data-carousel-item-previous' );
			item.removeAttribute( 'data-carousel-item-current' );
			item.removeAttribute( 'data-carousel-item-next' );
		} );

		previous.setAttribute( 'data-carousel-item-previous', '' );
		current.setAttribute( 'data-carousel-item-current', '' );
		next.setAttribute( 'data-carousel-item-next', '' );

		/**
		 * Update the progress element.
		 *
		 * This is not done with a data-attribute because iOS Safari 12 did not
		 * redraw the element after updating the attribute. The pseudo element
		 * content was stuck on the initial state (1/n).
		 */
		const progress = this.querySelector( '.carousel__progress' );
		if ( !progress ) {
			return;
		}
		progress.innerHTML = `${this.prettyPaddedIndexOne( index, length )}/${length}`;
	}

	get paused(): boolean {
		return !this.hasAttribute( 'data-carousel-playing' );
	}

	play() {
		return new Promise<void>( ( resolve ) => {
			requestAnimationFrame( () => {
				if ( !this.paused ) {
					// Already playing.
					resolve();

					return;
				}

				// set playing to true
				this.setAttribute( 'data-carousel-playing', '' );
				this.#ticker = window.setTimeout( this.#tick, this.#delay );


				const itemsContainer = this.querySelector( '[data-carousel-items]' );
				if ( itemsContainer ) {
					itemsContainer.setAttribute( 'aria-live', 'off' );
				}

				resolve();
			} );
		} );
	}

	pause() {
		// Always clear the timer, even when already paused.
		if ( null !== this.#ticker ) {
			window.clearTimeout( this.#ticker );
		}

		if ( this.paused ) {
			// Already paused.
			return Promise.resolve();
		}

		// set playing to false
		this.removeAttribute( 'data-carousel-playing' );

		const itemsContainer = this.querySelector( '[data-carousel-items]' );
		if ( itemsContainer ) {
			itemsContainer.setAttribute( 'aria-live', 'polite' );
		}

		return Promise.resolve();
	}

	private prettyPaddedIndexOne( index: number, l: number ): string {
		let pad = '0';
		const strIndex = `${index + 1}`;
		let length = l;

		while ( 10 <= ( length + 1 ) ) {
			length = length / 10;
			pad += '0';
		}

		return `${pad.substring( 0, pad.length - strIndex.length )}${strIndex}`;
	}
}

customElements.define( 'mr-carousel', MrCarousel );

function indexMinusOne( i: number, maxValue: number, looping: boolean ): number {
	if ( 2 > maxValue ) {
		return 0;
	}

	let index = i;

	index--;

	if ( 0 > index ) {
		if ( looping ) {
			return maxValue - 1;
		}

		return 0;

	}

	return index;
}

function indexPlusOne( i: number, maxValue: number, looping: boolean ): number {
	if ( 2 > maxValue ) {
		return 0;
	}

	let index = i;

	index++;

	if ( index >= maxValue ) {
		if ( looping ) {
			return 0;
		}

		return maxValue - 1;

	}

	return index;
}
