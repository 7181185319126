// Bugsnag.
import '@mrhenry/wp--bugsnag-config';

// Helpers.
import '@mrhenry/wp--autoplay-in-view';

// Mr Interactive.
import { MrInputSink } from '@mrhenry/wp--mr-interactive';

customElements.define( 'mr-input-sink', MrInputSink );

// Modules.
import './modules/mr-accordion';
import './modules/mr-carousel';
import './modules/mr-explainer';
import './modules/mr-navigation-overlay';
