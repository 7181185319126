import { MrModalDialog } from '@mrhenry/wp--mr-interactive';

class MrNavigationOverlay extends MrModalDialog {
	override openAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						opacity: 0,
					},
					{
						opacity: 1,
					},
				],
				{
					delay: 0,
					duration: 96,
					easing: 'linear',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this.querySelector( '.navigation-overlay__inner' ),
				[
					{
						transform: 'translate3d(0, 30px, 0)',
					},
					{
						transform: 'translate3d(0, 0, 0)',
					},
				],
				{
					delay: 32,
					duration: 480,
					easing: 'cubic-bezier(0.5, 0.35, 0.1, 1)',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this.querySelector( '.navigation-overlay__inner' ),
				[
					{
						opacity: 0,
					},
					{
						opacity: 1,
					},
				],
				{
					delay: 144,
					duration: 160,
					easing: 'linear',
					fill: 'forwards',
				}
			),
		];
	}

	override closeAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this.querySelector( '.navigation-overlay__inner' ),
				[
					{
						opacity: 1,
					},
					{
						opacity: 0,
					},
				],
				{
					delay: 0,
					duration: 96,
					easing: 'linear',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this,
				[
					{
						opacity: 1,
					},
					{
						opacity: 0,
					},
				],
				{
					delay: 32,
					duration: 96,
					easing: 'linear',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this.querySelector( '.navigation-overlay__inner' ),
				[
					{
						transform: 'translate3d(0, 30px, 0)',
					},
					{
						transform: 'translate3d(0, 0, 0)',
					},
				],
				{
					delay: 128,
					duration: 16,
					easing: 'linear',
					fill: 'forwards',
				}
			),
		];
	}

	override async willOpen() {
		await super.willOpen();
		document.body.classList.add( 'is-showing-navigation-overlay' );
	}

	override async willClose() {
		await super.willClose();
		document.body.classList.remove( 'is-showing-navigation-overlay' );
	}

	override firstFocusableElement(): HTMLElement | void {
		if ( !this.parentNode ) {
			return this;
		}

		const firstFocusableChild = this.querySelector( 'nav a' );
		if ( firstFocusableChild && firstFocusableChild instanceof HTMLElement ) {
			firstFocusableChild.setAttribute( 'data-forced-focus', '' );

			const removeForcedFocus = () => {
				firstFocusableChild.removeAttribute( 'data-forced-focus' );
				firstFocusableChild.removeEventListener( 'blur', removeForcedFocus );
			};

			firstFocusableChild.addEventListener( 'blur', removeForcedFocus, {
				once: true,
			} );

			return firstFocusableChild;
		}

		return this;
	}
}

customElements.define( 'mr-navigation-overlay', MrNavigationOverlay );
